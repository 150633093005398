<template>
  <div id="terminal_table">
    <div class="table">
      <div class="table_header">
        <div class="table_line_container">
          <div
            class="table_line_container__item check_box_column"
            style="border: unset"
          >
            <check-box
              v-on:click.stop
              @switch-value="selectAllTerminalCheckboxes"
              :checked="selectedAllChecked"
            />
          </div>
          <div
            class="table_line_container__item terminal_id_column terminal_id_title_container"
          >
            <div class="terminal_id_title_container__item title">
              <label>Терминал ID</label>
            </div>
            <div
              class="terminal_id_title_container__item icon"
              :class="[
                sortTerminalId === 'asc' ? 'icon_revesed' : '',
                activeSort === 'terminals' ? 'active_sort' : 'disable_sort',
              ]"
            >
              <button
                @click="
                  activeSort !== 'terminals'
                    ? this.$emit('switchSort', 'terminals', sortTerminalId)
                    : this.$emit('switchSort', 'terminals', 'desc')
                "
                v-if="sortTerminalId === 'asc'"
              >
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 1.66732H12V0.333984H0V1.66732Z"
                    fill="currentColor"
                  />
                  <path
                    d="M10.6667 5.66732H1.33333V4.33398H10.6667V5.66732Z"
                    fill="currentColor"
                  />
                  <path
                    d="M9.33333 9.66732H2.66667V8.33398H9.33333V9.66732Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
              <button v-else>
                <svg
                  @click="
                    activeSort !== 'terminals'
                      ? this.$emit('switchSort', 'terminals', sortTerminalId)
                      : this.$emit('switchSort', 'terminals', 'asc')
                  "
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 1.66732H12V0.333984H0V1.66732Z"
                    fill="currentColor"
                  />
                  <path
                    d="M10.6667 5.66732H1.33333V4.33398H10.6667V5.66732Z"
                    fill="currentColor"
                  />
                  <path
                    d="M9.33333 9.66732H2.66667V8.33398H9.33333V9.66732Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="table_line_container__item mac_column">MAC адрес</div>
          <div class="table_line_container__item status_column">Статус</div>
          <div
            class="table_line_container__item uptime_column terminal_id_title_container"
          >
            <div class="terminal_id_title_container__item title">
              <label>Uptime</label>
            </div>
            <div
              class="terminal_id_title_container__item icon"
              :class="[
                sortUpTime === 'asc' ? 'icon_revesed' : '',
                activeSort === 'uptime' ? 'active_sort' : 'disable_sort',
              ]"
            >
              <button
                @click="
                  activeSort !== 'uptime'
                    ? this.$emit('switchSort', 'uptime', sortUpTime)
                    : this.$emit('switchSort', 'uptime', 'desc')
                "
                v-if="sortUpTime === 'asc'"
              >
                <svg
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 1.66732H12V0.333984H0V1.66732Z"
                    fill="currentColor"
                  />
                  <path
                    d="M10.6667 5.66732H1.33333V4.33398H10.6667V5.66732Z"
                    fill="currentColor"
                  />
                  <path
                    d="M9.33333 9.66732H2.66667V8.33398H9.33333V9.66732Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
              <button v-else>
                <svg
                  @click="
                    activeSort !== 'uptime'
                      ? this.$emit('switchSort', 'uptime', sortUpTime)
                      : this.$emit('switchSort', 'uptime', 'asc')
                  "
                  width="12"
                  height="10"
                  viewBox="0 0 12 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0 1.66732H12V0.333984H0V1.66732Z"
                    fill="currentColor"
                  />
                  <path
                    d="M10.6667 5.66732H1.33333V4.33398H10.6667V5.66732Z"
                    fill="currentColor"
                  />
                  <path
                    d="M9.33333 9.66732H2.66667V8.33398H9.33333V9.66732Z"
                    fill="currentColor"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div class="table_line_container__item template_column">Шаблон</div>
          <div class="table_line_container__item model_column">Модель</div>
          <div class="table_line_container__item memory_column">Память</div>
          <div class="table_line_container__item action_column">Действия</div>
        </div>
      </div>
      <div
        v-if="getTerminals.length > 0"
        class="table_content"
        :style="getTerminals.length < 10 ? 'overflow: unset' : ''"
      >
        <div
          class="table_line_container"
          v-for="(terminal, i) in getTerminals"
          @click="clickTerminal(terminal.terminalId)"
          :key="terminal.terminalId"
          :class="[!(i % 2) ? 'blue' : 'white']"
        >
          <div
            v-on:click.stop
            class="table_line_container__item check_box_column"
          >
            <check-box
              v-on:click.stop
              @switch-value="clickTerminalCheckbox(terminal.terminalId, $event)"
              :checked="selectedTerminals.includes(terminal.terminalId)"
            />
          </div>
          <div class="table_line_container__item terminal_id_column">
            <div class="terminal_id_status_container">
              <div class="terminal_id_status_container__item terminal_status">
                <div
                  v-if="terminal.terminalState === 'OK'"
                  class="terminal_status_icon status_ok"
                >
                  <div
                    v-if="terminal.online == true"
                    class="status online"
                  ></div>
                  <div
                    v-if="terminal.online == false"
                    class="status offline"
                  ></div>

                  <div
                    v-if="terminal.online == null"
                    class="status no_info"
                  ></div>
                </div>
                <div
                  v-else-if="terminal.terminalState === 'BLOCKED'"
                  class="terminal_status_icon block_status"
                  title="Терминал заблокирован"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    fill="#000000"
                    height="15px"
                    width="15px"
                    version="1.1"
                    id="Layer_1"
                    viewBox="0 0 330 330"
                    xml:space="preserve"
                  >
                    <g id="XMLID_509_">
                      <path
                        id="XMLID_510_"
                        d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85   S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15   s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25   C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"
                      ></path>
                    </g>
                  </svg>
                </div>
                <div
                  v-else-if="terminal.terminalState === 'WAITING'"
                  class="terminal_status_icon status_waiting"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.0"
                    width="11pt"
                    height="11pt"
                    viewBox="0 0 512.000000 512.000000"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <g
                      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                      fill="#000000"
                      stroke="none"
                    >
                      <path
                        d="M2360 5094 c-164 -20 -220 -28 -323 -49 -423 -87 -849 -299 -1158 -576 -479 -429 -759 -948 -855 -1579 -25 -164 -25 -532 0 -690 96 -611 345 -1101 762 -1499 350 -334 751 -551 1216 -657 59 -13 124 -24 145 -24 103 1 203 101 203 205 0 66 -51 150 -111 180 -15 8 -71 24 -126 36 -827 178 -1467 816 -1648 1644 -36 163 -47 280 -47 475 0 328 65 612 208 909 317 658 924 1096 1664 1202 127 18 443 15 575 -5 298 -46 552 -138 803 -288 l82 -49 -90 -132 c-49 -72 -90 -141 -90 -152 0 -12 9 -30 20 -40 19 -17 33 -17 382 14 200 18 375 34 391 37 41 8 85 50 92 88 5 25 -19 126 -95 397 -55 200 -108 371 -117 381 -22 25 -55 23 -80 -4 -11 -13 -53 -70 -93 -128 -40 -58 -76 -109 -80 -113 -4 -5 -32 7 -61 26 -292 187 -643 317 -999 372 -100 16 -490 29 -570 19z"
                      />
                      <path
                        d="M2349 4177 c-18 -12 -44 -38 -56 -56 l-23 -34 0 -856 1 -856 762 -393 c435 -225 778 -396 799 -399 59 -9 105 5 143 42 66 66 68 170 4 231 -16 15 -331 184 -701 375 l-673 348 -5 759 -5 759 -28 36 c-15 20 -41 44 -57 52 -44 23 -121 19 -161 -8z"
                      />
                      <path
                        d="M4665 3601 c-80 -36 -132 -124 -122 -205 7 -60 65 -213 94 -247 84 -100 249 -92 325 16 44 63 47 122 10 229 -46 130 -72 169 -134 200 -61 31 -117 33 -173 7z"
                      />
                      <path
                        d="M4856 2830 c-42 -13 -90 -49 -117 -88 -21 -32 -24 -48 -27 -156 -4 -133 5 -179 45 -226 87 -104 248 -95 327 18 28 39 31 51 34 144 6 140 -9 199 -61 252 -58 57 -129 77 -201 56z"
                      />
                      <path
                        d="M4699 2012 c-54 -35 -69 -56 -103 -151 -54 -149 -52 -214 6 -286 92 -110 271 -95 335 29 30 58 73 197 73 236 0 116 -88 200 -208 200 -47 0 -67 -6 -103 -28z"
                      />
                      <path
                        d="M4354 1311 c-46 -21 -150 -138 -184 -207 -61 -124 23 -279 158 -292 95 -9 149 24 246 151 66 86 85 147 67 215 -14 50 -67 113 -115 136 -45 21 -121 20 -172 -3z"
                      />
                      <path
                        d="M3757 756 c-132 -71 -192 -125 -215 -195 -54 -157 118 -310 274 -245 64 26 188 113 211 147 29 43 41 102 31 157 -9 52 -29 81 -82 124 -31 26 -47 31 -104 34 -57 3 -74 0 -115 -22z"
                      />
                      <path
                        d="M2995 439 c-44 -10 -90 -24 -103 -31 -58 -30 -111 -113 -112 -173 0 -113 97 -215 205 -215 35 0 206 40 234 54 146 76 147 286 2 363 -54 28 -115 29 -226 2z"
                      />
                    </g>
                  </svg>
                </div>
              </div>
              <div
                class="terminal_id_status_container__item terminal_id"
                :title="this.wrapNullValue(terminal.terminalId)"
              >
                {{ this.wrapNullValue(terminal.terminalId) }}
              </div>
            </div>
          </div>
          <div
            class="table_line_container__item mac_column"
            :title="this.wrapNullValue(terminal.terminalMac)"
          >
            <label>{{ this.wrapNullValue(terminal.terminalMac) }}</label>
          </div>
          <div
            class="table_line_container__item status_column"
            :title="this.wrapNullValue(terminal.status)"
          >
            <label>{{ this.wrapNullValue(terminal.status) }}</label>
          </div>
          <div
            class="table_line_container__item uptime_column"
            :class="
              terminal.online && getUpTime(terminal).startsWith('- ')
                ? 'invalid_value'
                : ''
            "
            :title="
              [null, undefined].includes(terminal.rootfsBootTime) ||
              !terminal.online
                ? '-'
                : terminal.rootfsBootTime
            "
          >
            <label>{{ terminal.online ? getUpTime(terminal) : "-" }}</label>
          </div>
          <div
            class="table_line_container__item template_column"
            :title="this.wrapNullValue(terminal.template)"
          >
            <label>{{ this.wrapNullValue(terminal.template) }}</label>
          </div>
          <div
            class="table_line_container__item model_column"
            :title="getTerminalVendorInfo(terminal)"
          >
            <label>{{ getTerminalVendorInfo(terminal) }} </label>
          </div>
          <div
            class="table_line_container__item memory_column"
            :title="getTotalMemory(terminal)"
          >
            <label>{{ getTotalMemory(terminal) }}</label>
          </div>

          <div v-on:click.stop class="table_line_container__item action_column">
            <div class="action_buttons_container">
              <Transition name="terminal-panel-dropdown-menu">
                <div
                  v-on-click-outside="[
                    closeOtherButton,
                    { ignore: getOptions },
                  ]"
                  v-if="showOtherButtonId === terminal.terminalId"
                  class="dropdown_actions_menu"
                  :class="i > terminals.length - 4 ? 'top_menu' : ''"
                >
                  <div
                    ref="optionDropDownMenu"
                    class="dropdown_actions_menu__item dropdown_actions_menu_content_container"
                  >
                    <div
                      class="dropdown_actions_menu_content_container__item options_button_container"
                      :class="[terminal.online !== true ? 'no_addition_options' : '']"
                    >
                      <div
                        v-if="terminal.online"
                        class="options_button_container__item"
                      >
                        <button-with-confirm
                          text="Перезапустить контент"
                          callback="restartTerminalContent"
                          @restart-terminal-content="
                            $emit(
                              'restartTerminalContent',
                              terminal.terminalId
                            );
                            showOtherButtonId = null;
                          "
                        />
                      </div>
                      <div
                        v-if="terminal.online"
                        class="options_button_container__item"
                      >
                        <button-with-confirm
                          text="Пересоздать кэш"
                          callback="resetCache"
                          @reset-cache="
                            $emit('resetCache', terminal.terminalId);
                            showOtherButtonId = null;
                          "
                        />
                      </div>
                      <div
                        v-if="terminal.online"
                        class="options_button_container__item"
                      >
                        <button-with-confirm
                          text="Перезагрузить терминал"
                          callback="restartTerminal"
                          @restart-terminal="
                            $emit('restartTerminal', terminal.terminalId);
                            showOtherButtonId = null;
                          "
                        />
                      </div>
                      <Transition
                        name="terminal-panel-dropdown-menu-broad-option"
                      >
                        <div
                          v-if="
                            terminal.online && dropOptionMenu.showBroadOptions
                          "
                          class="options_button_container__item"
                        >
                          <button
                            class="dropdown_actions_button"
                            @click="
                              $emit('sendTerminalNotify', terminal.terminalId);
                              showOtherButtonId = null;
                            "
                          >
                            Отправить уведомление
                          </button>
                        </div>
                      </Transition>

                      <Transition
                        name="terminal-panel-dropdown-menu-broad-option"
                      >
                        <div
                          v-if="
                            (terminal.terminalState !== 'WAITING' &&
                            dropOptionMenu.showBroadOptions) ||
                            terminal.online !== true
                          "
                          class="options_button_container__item"
                        >
                          <button-with-confirm
                            text="Отозвать лицензию"
                            callback="unregisterTerminal"
                            @unregister-terminal="
                              $emit('unregisterTerminal', terminal.terminalId);
                              showOtherButtonId = null;
                            "
                          />
                        </div>
                      </Transition>
                    </div>
                    <div v-if="terminal.online === true" 
                    @click="
                          dropOptionMenu.showBroadOptions =
                            !dropOptionMenu.showBroadOptions
                        "
                      class="dropdown_actions_menu_content_container__item broad_option_button"
                    >
                      <button
                        
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          xmlns:xlink="http://www.w3.org/1999/xlink"
                          fill="#000000"
                          height="15px"
                          width="15px"
                          version="1.1"
                          id="Layer_1"
                          viewBox="0 0 330 330"
                          xml:space="preserve"
                          :transform="
                            dropOptionMenu.showBroadOptions ? 'scale(1,-1)' : ''
                          "
                        >
                          <path
                            id="XMLID_225_"
                            d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393  c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393  s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
              </Transition>
              <div
                v-if="terminal.terminalState === 'WAITING'"
                class="action_buttons_container__item primary_actions_waiting_container"
              >
                <div class="primary_actions_waiting_container__item"></div>
                <lock-button
                  :text="'Разрешить'"
                  callback="allowRegisterTerminal"
                  @allow-register-terminal="
                    $emit('allowRegisterTerminal', terminal.terminalId)
                  "
                />
              </div>
              <div
                v-else
                class="action_buttons_container__item primary_actions_container"
              >
                <div class="primary_actions_container__item">
                  <button
                    class="primary_button"
                    title="Получить скриншот"
                    v-on:click.stop
                    :class="terminal.online ? '' : 'disable'"
                    @click="
                      terminal.online
                        ? $emit('clickMakeScreen', terminal.terminalId)
                        : null
                    "
                  >
                    <svg
                      width="20"
                      height="17"
                      viewBox="0 0 20 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M15.8334 5.16602V3.49935H14.1667V1.83268H15.8334V0.166016H17.5V1.83268H19.1667V3.49935H17.5V5.16602H15.8334ZM2.50004 16.8327C2.04171 16.8327 1.64949 16.6696 1.32337 16.3435C0.996707 16.0168 0.833374 15.6243 0.833374 15.166V5.16602C0.833374 4.70768 0.996707 4.31546 1.32337 3.98935C1.64949 3.66268 2.04171 3.49935 2.50004 3.49935H5.12504L6.66671 1.83268H11.6667V3.49935H7.39587L5.87504 5.16602H2.50004V15.166H15.8334V7.66602H17.5V15.166C17.5 15.6243 17.337 16.0168 17.0109 16.3435C16.6842 16.6696 16.2917 16.8327 15.8334 16.8327H2.50004ZM9.16671 13.916C10.2084 13.916 11.0939 13.5516 11.8234 12.8227C12.5523 12.0932 12.9167 11.2077 12.9167 10.166C12.9167 9.12435 12.5523 8.23879 11.8234 7.50935C11.0939 6.78046 10.2084 6.41602 9.16671 6.41602C8.12504 6.41602 7.23948 6.78046 6.51004 7.50935C5.78115 8.23879 5.41671 9.12435 5.41671 10.166C5.41671 11.2077 5.78115 12.0932 6.51004 12.8227C7.23948 13.5516 8.12504 13.916 9.16671 13.916ZM9.16671 12.2493C8.58337 12.2493 8.09032 12.048 7.68754 11.6452C7.28476 11.2424 7.08337 10.7493 7.08337 10.166C7.08337 9.58268 7.28476 9.08963 7.68754 8.68685C8.09032 8.28407 8.58337 8.08268 9.16671 8.08268C9.75004 8.08268 10.2431 8.28407 10.6459 8.68685C11.0487 9.08963 11.25 9.58268 11.25 10.166C11.25 10.7493 11.0487 11.2424 10.6459 11.6452C10.2431 12.048 9.75004 12.2493 9.16671 12.2493Z"
                        :fill="terminal.online ? '#010101' : '#a1a3ab'"
                      />
                    </svg>
                  </button>
                </div>
                <div class="primary_actions_container__item">
                  <button
                    class="primary_button"
                    title="Получить логи"
                    v-on:click.stop
                    @click="
                      terminal.online
                        ? $emit('clickMakeLog', terminal.terminalId)
                        : null
                    "
                    :class="terminal.online ? '' : 'disable'"
                  >
                    <svg
                      width="17"
                      height="18"
                      viewBox="0 0 17 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M16.1667 13.4993H13.6667V10.9993H12V13.4993H9.50004V15.166H12V17.666H13.6667V15.166H16.1667M2.00004 0.166016C1.55801 0.166016 1.13409 0.34161 0.821529 0.654171C0.508969 0.966731 0.333374 1.39065 0.333374 1.83268V15.166C0.333374 16.091 1.07504 16.8327 2.00004 16.8327H8.50837C8.20837 16.316 8.00004 15.7493 7.90004 15.166H2.00004V1.83268H7.83337V5.99935H12V9.39935C12.275 9.35768 12.5584 9.33268 12.8334 9.33268C13.1167 9.33268 13.3917 9.35768 13.6667 9.39935V5.16602L8.66671 0.166016M3.66671 8.49935V10.166H10.3334V8.49935M3.66671 11.8327V13.4993H7.83337V11.8327H3.66671Z"
                        :fill="terminal.online ? '#010101' : '#a1a3ab'"
                      />
                    </svg>
                  </button>
                </div>
                <div class="primary_actions_container__item">
                  <button
                    class="primary_button"
                    title="Получить ssh"
                    v-on:click.stop
                    @click="
                      terminal.online
                        ? $emit('clickMakeSsh', terminal.terminalId)
                        : null
                    "
                    :class="terminal.online ? '' : 'disable'"
                  >
                    <svg
                      width="19"
                      height="8"
                      viewBox="0 0 19 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.68754 0.875C1.06254 0.875 0.645874 1.39583 0.645874 1.91667V3.73958C0.645874 4.26042 1.06254 4.78125 1.68754 4.78125H4.29171V5.5625H0.645874V7.125H4.81254C5.43754 7.125 5.85421 6.60417 5.85421 6.08333V4.26042C5.85421 3.73958 5.43754 3.21875 4.81254 3.21875H2.20837V2.4375H5.85421V0.875H1.68754ZM7.93754 0.875C7.31254 0.875 6.89587 1.39583 6.89587 1.91667V3.73958C6.89587 4.26042 7.31254 4.78125 7.93754 4.78125H10.5417V5.5625H6.89587V7.125H11.0625C11.6875 7.125 12.1042 6.60417 12.1042 6.08333V4.26042C12.1042 3.73958 11.6875 3.21875 11.0625 3.21875H8.45837V2.4375H12.1042V0.875H7.93754ZM13.1459 0.875V7.125H14.7084V4.52083H16.7917V7.125H18.3542V0.875H16.7917V2.95833H14.7084V0.875H13.1459Z"
                        :fill="terminal.online ? '#010101' : '#a1a3ab'"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              <div
                
                class="action_buttons_container__item drop_menu_button"
              >
                <button
                  ref="ignoreElRef"
                  @click="onClickDropOptionPrimaryButton(terminal)"
                  class="dropdown_actions_menu__show_button primary_button"
                  :class="
                    terminal.terminalState === 'WAITING' &&
                    terminal.online !== true
                      ? 'disable'
                      : ''
                  "
                >
                  <svg
                    width="4"
                    height="17"
                    viewBox="0 0 4 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.66663 1.83268C3.66663 2.75316 2.92043 3.49935 1.99996 3.49935C1.07948 3.49935 0.333292 2.75316 0.333292 1.83268C0.333292 0.912208 1.07948 0.166016 1.99996 0.166016C2.92043 0.166016 3.66663 0.912208 3.66663 1.83268ZM3.66663 15.166C3.66663 16.0865 2.92043 16.8327 1.99996 16.8327C1.07948 16.8327 0.333291 16.0865 0.333291 15.166C0.333291 14.2455 1.07948 13.4993 1.99996 13.4993C2.92043 13.4993 3.66663 14.2455 3.66663 15.166ZM3.66663 8.49935C3.66663 9.41982 2.92043 10.166 1.99996 10.166C1.07948 10.166 0.333292 9.41982 0.333292 8.49935C0.333292 7.57887 1.07948 6.83268 1.99996 6.83268C2.92043 6.83268 3.66663 7.57887 3.66663 8.49935Z"
                      :fill="
                        terminal.terminalState === 'WAITING' &&
                        terminal.online !== true
                          ? '#a1a3ab'
                          : '#010101'
                      "
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="not_found_content" v-else>Не найдено</div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";
// eslint-disable-next-line no-unused-vars
import { vOnClickOutside } from "@vueuse/components";

export default {
  components: {
    "lock-button": defineAsyncComponent(() =>
      import("@/components/buttons/LockButton.vue")
    ),
    "check-box": defineAsyncComponent(() =>
      import("@/components/CheckBox.vue")
    ),
    "button-with-confirm": defineAsyncComponent(() =>
      import("@/components/buttons/ButtonWithConfirm.vue")
    ),
    // OnClickOutside,
  },

  name: "TerminalTable",
  props: {
    terminals: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedTerminals: {
      type: Array,
      default() {
        return [];
      },
    },
    selectedAllChecked: {
      type: Boolean,
      default() {
        return false;
      },
    },
    sortTerminalId: {
      type: String,
      default() {
        return "desc";
      },
    },
    sortUpTime: {
      type: String,
      default() {
        return "asc";
      },
    },
    activeSort: {
      type: String,
      default() {
        return "terminals";
      },
    },
  },
  data() {
    return {
      showOtherButtonId: null,
      dropOptionMenu: {
        showBroadOptions: false,
      },
    };
  },

  methods: {
    onClickDropOptionPrimaryButton(terminal) {
      if (terminal.terminalState === "WAITING" && terminal.online !== true) {
        return;
      }

      if (this.showOtherButtonId !== terminal.terminalId) {
        this.showOtherButtonId = terminal.terminalId;
        this.dropOptionMenu.showBroadOptions = false;
        return;
      }

      this.showOtherButtonId = null;
    },
    closeOtherButton() {
      this.showOtherButtonId = null;
      this.dropOptionMenu.showBroadOptions = false;
    },
    convertMsToTime(milliseconds) {
      const prefix = milliseconds < 0 ? "- " : "";
      milliseconds = Math.abs(milliseconds);
      let minutes = Math.floor(milliseconds / 60000);
      let hours = Math.floor(minutes / 60);
      let days = Math.floor(hours / 24);
      minutes = minutes % 60;
      hours = hours % 24;
      days = days > 0 ? `${days}d ` : "";

      return `${prefix}${days}${hours}h ${Math.abs(minutes)}m `;
    },
    // get up time
    getUpTime(terminal) {
      if ([null, undefined].includes(terminal.rootfsBootTime)) {
        return "-";
      }
      const currentTime = new Date();
      const target = Date.parse(`${terminal.rootfsBootTime}Z`);

      return this.convertMsToTime(currentTime - target);
    },
    // switch sort type
    wrapNullValue(value) {
      return !value ? "Неизвестно" : value;
    },

    // click on terminal
    clickTerminal(terminalId) {
      this.$emit("terminalTableTerminalClick", terminalId);
    },

    // click on terminal ckeckbox
    clickTerminalCheckbox(terminalId, event) {
      this.$emit("terminalTableTerminalSelectTerminal", terminalId, event);
    },

    // click on terminal ckeckbox
    selectAllTerminalCheckboxes(value) {
      this.$emit("terminalTableSelectTerminalAll", value);
    },

    // forms terminal vendor info
    getTerminalVendorInfo(terminal) {
      return !terminal.devices.boardVendor && !terminal.devices.boardName
        ? "Неизвестно"
        : `${terminal.devices.boardVendor} ${terminal.devices.boardName}`;
    },
    // get total memory
    getTotalMemory(terminal) {
      return terminal.devices.totalMemSizeMB
        ? `${terminal.devices.totalMemSizeMB} MB`
        : "-";
    },
  },

  computed: {
    getOptions() {
      return [...this.$refs.ignoreElRef];
    },
    getTerminals() {
      return this.terminals.slice();
    },
  },
  created() {},

  directives: {
    onClickOutside: vOnClickOutside,
  },

  watch: {
    terminals() {},
  },
};
</script>

<style lang="less">
#terminal_table {
  .terminal-panel-dropdown-menu-enter-active {
    animation: terminal-panel-dropdown-menu-in 0.4s;
  }
  .terminal-panel-dropdown-menu-leave-active {
    animation: terminal-panel-dropdown-menu-in 0.1s reverse;
  }

  @keyframes terminal-panel-dropdown-menu-in {
    0% {
      transform: scale(0.7) translateX(100px);
    }
    50% {
      transform: scale(1.01);
      // opacity: 90%;
    }
    100% {
      transform: scale(1);
    }
  }

  .terminal-panel-dropdown-menu-broad-option-enter-active {
    animation: terminal-panel-dropdown-menu-broad_option 0.2s;
  }

  .terminal-panel-dropdown-menu-broad-option-leave-active {
    animation: terminal-panel-dropdown-menu-broad_option 0.1s reverse;
  }


  @keyframes terminal-panel-dropdown-menu-broad_option {
    0% {
      transform: translateY(20px);
    }
    100% {
      transform: scale(1);
    }
  }
  

  &::-webkit-scrollbar {
    display: none;
  }

  .table {
    margin-top: 15px;
    margin-bottom: 20px;
    &::-webkit-scrollbar {
      display: none; /* Safari and Chrome */
    }
    // display: flex;
    // flex-direction: column;
    .table_header {
      font-weight: 500;
      font-size: 15px;
      font-family: Roboto;
      font-style: normal;
      line-height: 18px;
      position: sticky;
      top: 0;
      background-color: white;
      height: 40px;
      z-index: 0;

      .table_line_container {
        height: 30px;
        cursor: unset;
        .table_line_container__item {
          cursor: unset;
          border-left: 1px solid rgba(65, 80, 183, 0.3);
          padding: 10px 11px 11px;
          height: 100%;
          font-size: 15px;
        }
      }
    }
    .table_line_container {
      display: flex;
      height: 40px;
      align-items: center;

      &.white {
        background: none;
      }
      &.blue {
        background: #f1f6ff;
      }
      .table_line_container__item {
        cursor: pointer;
        display: flex;
        justify-content: flex-start;
        white-space: nowrap;
        padding: 10px 11px 11px;
        display: flex;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;

        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;

        label {
          max-width: 98%;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }
        @media (max-width: 500px) {
          &.terminal_id_column {
            width: 40%;
            font-size: 13px;
          }
          &.mac_column {
            display: none;
          }
          &.status_column {
            display: none;
          }
          &.uptime_column {
            display: none;
          }

          &.template_column {
            display: none;
          }

          &.model_column {
            display: none;
          }

          &.memory_column {
            display: none;
          }

          &.action_column {
            width: 50%;
            font-size: 13px;
            overflow: unset;
            cursor: unset;
          }
        }
        @media (min-width: 500px) {
          &.check_box_column {
            width: 4%;
            display: flex;
            justify-content: center;
            cursor: unset;
            input {
              cursor: pointer;
              width: 14px;
              height: 14px;
            }
          }
          &.terminal_id_column {
            width: 11.5%;
          }

          &.mac_column {
            width: 12.2%;
          }

          &.status_column {
            width: 19.6%;
          }
          &.uptime_column {
            width: 8%;

            &.invalid_value {
              color: #ed2f2f;
            }
          }

          &.template_column {
            width: 9%;
          }

          &.model_column {
            width: 12%;
          }

          &.memory_column {
            width: 6%;
          }

          &.action_column {
            width: 15%;
            overflow: unset;
            cursor: unset;
          }
        }
      }
    }
    .table_content {
      // overflow-y: scroll;
      // max-height: calc(100vh * 0.95);
    }
    .not_found_content {
      display: flex;
      justify-content: center;
      height: 200px;
      align-items: center;
    }
  }
  .terminal_id_status_container {
    display: flex;
    .terminal_status {
      margin-right: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      .status {
        height: 15px;
        width: 15px;
        border-radius: 50%;
        &.online {
          background-color: #5bc367;
        }

        &.offline {
          background-color: #f44c4b;
        }

        &.no_info {
          background-color: #a1a3ab;
        }
      }
      .terminal_status_icon {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .terminal_id_title_container {
    display: flex;
    align-items: center;

    .icon {
      margin-left: 12px;

      button {
        all: unset;
        cursor: pointer;
      }

      &.icon_revesed {
        transform: rotate(180deg);
      }

      &.active_sort {
        color: #4151b7;
      }
      &.disable_sort {
        color: #61606061;

        &:hover {
          color: #4151b7;
          transition: 0.5s;
        }
      }
    }
  }
  .action_buttons_container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: flex-start;
    .drop_menu_button {
      margin-left: auto;
    }
    .primary_button {
      all: unset;
      padding: 5px 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      cursor: pointer;

      &.disable {
        &:hover {
          background-color: unset;
          cursor: unset;
        }
      }
      &:hover {
        background-color: #4151b720;
        border-radius: 10px;
        transition: 0.5s;
      }
    }
  }
  .primary_actions_container {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  .primary_actions_waiting_container {
    .button_content_container {
      width: 120px;
    }
  }
}

.dropdown_actions_menu {
  position: relative;
  display: inline-block;
  right: 60px;
  margin-top: -25px;

  &.top_menu {
    // transform: translate(-210px, 10px);
    margin-top: unset;
    margin-bottom: -13px;
    .dropdown_actions_menu_content_container {
      bottom: 100%;
    }
  }

  .dropdown_actions_menu_content_container {
    position: absolute;
    z-index: 1;
    display: flex;
    flex-direction: column;
    box-shadow: 0px 8px 5px 0px rgba(0, 0, 0, 0.2);
    width: 180px;
    border-radius: 10px;
    transition: 0.5s;

    .options_button_container {
      background-color: #fcfafa;
      padding: 7px 20px 5px 10px;
      border-top-right-radius: 10px;
      border-top-left-radius: 10px;
      width: 100%;
      transition: 0.5s;
      &.no_addition_options {
        border-radius: 10px;
        padding: 7px 20px 7px 10px;

      }
    }

    .broad_option_button {
      width: 100%;
      background-color: #f2f3f7;
      padding: 7px 20px 3px 10px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      display: flex;
      justify-content: center;
      cursor: pointer;

      &:hover {
        background-color: #d7ddf4;
        transition: 0.5s;
      }
      button {
        all: unset;
        display: flex;
        justify-content: center;
        width: 90%;
      }
    }

    .options_button_container__item {
      margin-top: 3px;
      height: 25px;

      .button_with_confirm_container {
        width: 100%;
        .regular_button {
          all: unset;
          padding: 4px 6px;
          width: 100%;
          cursor: pointer;
          &:hover {
            width: 100%;
            background-color: #4151b720;
            border-radius: 5px;
            transition: 0.5s;
          }
        }
      }

      &.delete_terminal_button {
        color: red;
      }
    }
  }
  .dropdown_actions_button {
    all: unset;
    padding: 4px 6px;
    width: 100%;
    cursor: pointer;
    &:hover {
      width: 100%;
      background-color: #4151b720;
      border-radius: 5px;
      transition: 0.5s;
    }
  }
}
</style>
