<template>
  <div id="terminals_page">
    <notify-bar ref="notifyBar" />
    <Transition name="terminal-panel">
      <terminal-panel
        v-if="selectedTerminalId"
        :terminal="terminalExtendedInfo[selectedTerminalId]"
        @close-teminal-panel="selectedTerminalId = null"
        @update-terminal-data="onUpdateTerminal"
      />
    </Transition>

    <div class="terminal_title">
      <label>Терминалы</label>
    </div>
    <search
      @click="selectedTerminalId = null"
      @make-search="makeSearch"
      @update-page="refreshPage"
      :templates="templates"
      :models="models"
      :distributions="distributions"
      :displayResolutions="displayResolutions"
      :transportAgents="transportAgents"
      :transportAgentPeerStates="transportAgentPeerStates"
      :terminalStates="terminalStates"
    />
    <page-navigation
      :pageStep="terminalsPerPage"
      :totalCount="totalCount"
      :hasNextPage="hasNextPage"
      :currentPage="currentPage"
      :anySelectTerminal="selectedTerminals.length > 0"
      @page-navigation-next-page="onPageForward"
      @page-navigation-previous-page="onPagePrevios"
      @page-navigation-last-page="onPageLast"
      @page-navigation-first-page="makeSearch(searchFilters)"
      @page-navigation-per-page="onPerPage"
      @update-selected-terminals="onUpdateSelectedTerminals"
      @export-selected-terminals="onExportSelectedTerminals"
    />
    <Transition name="terminal-table">
      <terminal-table
        v-if="!showTerminalLoad"
        :selectedTerminals="selectedTerminals"
        :terminals="terminals"
        :sortTerminalId="sortTerminalId"
        :selectedAllChecked="selectedAllChecked"
        :activeSort="activeSortType"
        :sortUpTime="sortUpTime"
        @switch-sort="onSwitchTerminalTableSort"
        @restart-terminal-content="onRestartTerminalContent"
        @terminal-table-terminal-select-terminal="onHandleSelectTerminal"
        @terminal-table-select-terminal-all="onHandleSelectTerminalAll"
        @terminal-table-terminal-click="onHandleSelectTerminalClick"
        @click-make-screen="onHandleMakeScreen"
        @click-make-log="onHandleMakeLogs"
        @click-make-ssh="onHandleMakeSSH"
        @restart-terminal="onRestartTerminal"
        @send-terminal-notify="onSendTerminalNotify"
        @reset-cache="onResetCache"
        @allow-register-terminal="onAllowRegisterTerminal"
        @unregister-terminal="onUnregisterTerminall"
      />
      <div v-else class="terminals_load_container">
        <span class="loader"></span>
      </div>
    </Transition>
  </div>
</template>

<script>
// import axios from "axios";
import Search from "../components/Search.vue";
import PageNavigation from "../components/PageNavigation.vue";
import TerminalTable from "../components/TerminalTable.vue";
import TerminalPanel from "../components/TerminalPanel.vue";
import NotifyBar from "../components/NotifyBar.vue";
import { sshUser, sshHost } from "../config.js";

export default {
  name: "Terminals",
  components: {
    search: Search,
    "page-navigation": PageNavigation,
    "terminal-table": TerminalTable,
    "terminal-panel": TerminalPanel,
    "notify-bar": NotifyBar,
  },
  data() {
    return {
      //animations
      showTerminalLoad: true,

      //terminals
      terminals: [],
      terminalExtendedInfo: {},
      terminalTableQuery: `
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      edges {
        node {
          terminalId
          terminalMac
          terminalState
          status
          template
          online
          rootfsBootTime
          devices {
            totalMemSizeMB
            boardVendor
            boardName

          }
        }
      }
      `,
      terminalPanelQuery: `
      edges {
    node {
      terminalMac
      terminalId
      terminalState
      online
      status
      template
      lastConnect
      lastUpdate
      rootfsVersion
      rootfsBootTime
      kernelVersion
      distributions {
        edges {
          node {
            type
            distribution
            params
          }
        }
      }
      networks {
        edges {
          node {
            macAddr
            nicName
            duplex
            speed
            mtu
            modelID
            vendorID
            revisionID
            ipAddr {
              edges {
                node {
                  ipv4
                  netmask
                }
              }
            }

          }
        }

      }
      devices {
        cpuName
        cpuCores
        boardVendor
        boardName
        totalMemSizeMB
        volumeLevel
        storages {
          edges {
            node {
              model
              path
              size
              partitions {
                edges {
                  node {
                    path
                    size
                    filesystem
                    label
                  }
                }
              }
            }
          }
        }
        touchDevices {
              edges {
                node {
                  mapToOutput
                  name
                  path
                }
              }
        }
        inputDevices {
              edges {
                node {
                  path
                  model
                  vendor
                  isMouse
                  modelID
                  vendorID
                  isKeyboard
                  revisionID
                  isTouchscreen
                }
              }
            }
      }
      displays {
        positions
        positionMode
        outputs {
          edges {
            node {
              output
              currentMode
              currentResolution
              id
              name
              primary
              rotation
              serial
              supportedModes
              supportedResolutions
              turnedOn
              windows
            }
          }
        }
      }
      transport {
        agent
        state
        creatingDttm
      }

    }
    
  }
      `,

      // terminalInfo
      selectedTerminalId: null,

      // search
      templates: [],
      models: [],
      distributions: [],
      displayResolutions: [],
      transportAgents: [],
      transportAgentPeerStates: [],
      sortTerminalId: "desc", // asc / desc
      sortUpTime: "asc", // asc / desc
      activeSortType: "terminals", // terminals, uptime
      terminalStates: [],

      // navigation
      terminalsPerPage: 20,
      totalCount: 0,
      hasNextPage: false,
      endCursor: null,
      startCursor: null,
      searchFilters: null,
      previosPageEndCursor: null,
      forwardPageStartCursor: null,
      currentPage: 1,

      // terminals table
      selectedTerminals: [],
      selectedAllChecked: false,
    };
  },

  methods: {
    downloadTerminal() {
      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.terminal, null, 2));
      var downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute(
        "download",
        `terminal_${this.terminal.terminalId}_export` + ".json"
      );
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
    async onExportSelectedTerminals() {
      // check if all extend data avaliable
      const needExtendInfo = this.selectedTerminals.filter(
        (x) => !Object.keys(this.terminalExtendedInfo).includes(String(x))
      );

      // get extended data fro aggregation
      if (needExtendInfo.length > 0) {
        let terminalsParams = `terminal_id_in: [${needExtendInfo}] `;
        // make rquest and extract data
        let terminalRaw = await this.makeGraphQLTerminalRequest(
          terminalsParams,
          this.terminalPanelQuery
        );
        if (terminalRaw) {
          const terminals = this.unpackRelyEdges(terminalRaw.terminals);
          terminals.forEach((el) => {
            this.terminalExtendedInfo[String(el.terminalId)] =
              this.unpackExtendedTerminalInfoGraphQL(el);
          });
        }
      }

      // download
      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(
          JSON.stringify(Object.values(this.terminalExtendedInfo), null, 2)
        );
      var downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", `terminals_export` + ".json");
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
    async onUpdateSelectedTerminals() {
      await this.updateTerminalData(this.selectedTerminals);
    },
    onUpdateTerminal(terminalId, showNotify = false) {
      this.updateTerminalData([terminalId], showNotify);
    },
    // updates terminal data (force update in integration)
    async updateTerminalData(terminalIds, showNotify = true) {
      if (showNotify) {
        this.$refs.notifyBar.addWaitMsg({
          overWriteMsg:
            terminalIds.length > 1
              ? "Обновление терминалов"
              : "Обновление терминала",
        });
      }
      const chunkSize = 20;
      for (let i = 0; i < terminalIds.length; i += chunkSize) {
        const chunk = terminalIds.slice(i, i + chunkSize);
        await this.$http
          .post(`${this.$backEndUrl}/v1/proxy/aggregation`, {
            url: `/v2/terminals?ids=${chunk.join(",")}`,
            method: "PUT",
            payload: {},
            headers: {},
          })
          .then((res) => {
            if (res.data.statusCode !== 200) {
              console.log(
                "Response from proxy aggregation obtained (force update terminal) err",
                res.data.payload
              );
              if (showNotify) {
                this.$refs.notifyBar.addErrorMsg({
                  overWriteMsg:
                    terminalIds.length > 1
                      ? "Ошибка обновления терминалов"
                      : "Ошибка обновления терминала",
                });
                return;
              }
            }
            if (showNotify) {
              this.$refs.notifyBar.addOKMsg({
                overWriteMsg:
                  terminalIds.length > 1
                    ? "Терминалы обновлены"
                    : "Терминал обновлен",
              });
            }
            console.log(
              "Response from aggregation obtained (force update terminal)",
              res.data.payload
            );
            for (const el of res.data.payload.terminals) {
              // update in extended
              this.terminalExtendedInfo[el.terminalId] = el;
              // update in terminals
              const terminalIndex = this.terminals.findIndex(
                (e) => e.terminalId == el.terminalId
              );
              this.terminals[terminalIndex] = el;
            }
          })
          .catch((error) => {
            console.log(
              "Error to get aggregation data (force update terminal)",
              error
            );
            if (showNotify) {
              this.$refs.notifyBar.addErrorMsg({
                overWriteMsg:
                  terminalIds.length > 1
                    ? "Ошибка обновления терминалов"
                    : "Ошибка обновления терминала",
              });
            }
          });
      }
    },

    // callback on switch sort type per page
    onSwitchTerminalTableSort(activeSort, sortType) {
      this.activeSortType = activeSort;
      switch (activeSort) {
        case "terminals":
          this.sortTerminalId = sortType;
          break;
        case "uptime":
          this.sortUpTime = sortType;
          break;
      }

      this.makeSearch(this.searchFilters);
    },

    // callback on terminals per page
    onPerPage(value) {
      if (this.terminalsPerPage != value) {
        this.terminalsPerPage = value;
        this.selectedTerminals = [];
      }
      this.makeSearch(this.searchFilters);
    },

    // get filters data
    async getFilterData() {
      const result = this.$http
        .post(`${this.$backEndUrl}/v1/proxy/aggregation`, {
          url: `/v2/filterScheme`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((res) => {
          console.log("Response from proxy aggregation obtained", res);
          if (res.data.statusCode === 200) {
            console.log("Response from aggregation obtained", res.data.payload);
            this.templates = res.data.payload.templates;
            this.models = res.data.payload.models;
            this.distributions = res.data.payload.distributions;
            this.displayResolutions = res.data.payload.displayResolutions;
            this.transportAgents = res.data.payload.transportAgents;
            this.transportAgentPeerStates =
              res.data.payload.transportAgentPeerStates;
            this.terminalStates = res.data.payload.terminalStates;
          }
        })
        .catch((error) => {
          console.log("Error to get search data", error);
        });

      return await result;
    },

    // unpacks graph ql rely data
    unpackRelyEdges(data) {
      return data["edges"].length > 0 ? data["edges"].map((e) => e.node) : [];
    },

    // extracts data from graphql to component
    extractTerminalDataFromGraph(terminalRaw) {
      this.totalCount = terminalRaw.terminals.totalCount;
      this.hasNextPage = terminalRaw.terminals.pageInfo.hasNextPage;
      this.endCursor = terminalRaw.terminals.pageInfo.endCursor;
      this.startCursor = terminalRaw.terminals.pageInfo.startCursor;
      let terminals = this.unpackRelyEdges(terminalRaw.terminals);
      // terminals.map(e => e.devices.inputDevices = this.unpackRelyEdges(e.devices.inputDevices))
      this.terminals = terminals;
    },

    // create graphql params from filters
    formSearchParamsByFilter(filters) {
      let terminalsParams = "";

      // online filter
      switch (this.activeSortType) {
        case "terminals":
          if (this.sortTerminalId === "asc") {
            terminalsParams += `order_by: "terminalId" `;
          } else {
            terminalsParams += `order_by: "-terminalId" `;
          }
          break;
        case "uptime":
          if (this.sortUpTime === "asc") {
            terminalsParams += `order_by: "-rootfsBootTime" `;
          } else {
            terminalsParams += `order_by: "rootfsBootTime" `;
          }
          break;
      }

      // online filter
      if (filters.online) {
        terminalsParams += `online: true `;
      }

      // terminalId filter
      if (filters.terminalIds) {
        terminalsParams += `terminal_id_in: [${filters.terminalIds}] `;
      }

      // terminalId filter
      if (filters.terminalMacs) {
        terminalsParams += `terminal_mac_in: ["${filters.terminalMacs
          .map((el) => {
            return el.toLowerCase();
          })
          .join('","')}"] `;
      }

      // terminal IP
      if (filters.terminalIP) {
        terminalsParams += `ip: "${filters.terminalIP}" `;
      }

      // memory from
      if (filters.memoryFrom) {
        terminalsParams += `min_ram: ${filters.memoryFrom} `;
      }

      // memory to
      if (filters.memoryTo) {
        terminalsParams += `max_ram: ${filters.memoryTo} `;
      }

      // template
      if (filters.template) {
        terminalsParams += `template: "${filters.template}" `;
      }

      // vendor
      if (filters.vendor) {
        terminalsParams += `device_vendor: "${filters.vendor}" `;
      }

      // model
      if (filters.model) {
        terminalsParams += `device_model: "${filters.model}" `;
      }

      // distribution type
      if (filters.distributionType) {
        terminalsParams += `distr_type: "${filters.distributionType}" `;
      }

      // distribution build
      if (filters.distributionBuild) {
        terminalsParams += `distr_version: "${filters.distributionBuild}" `;
      }

      // display number
      if (filters.displayNumber) {
        terminalsParams += `displays_num: ${filters.displayNumber} `;
      }

      // display resolution
      if (filters.displayResolution) {
        terminalsParams += `displays_resolution: "${filters.displayResolution}" `;
      }

      // trasport agent
      if (filters.transportAgent) {
        terminalsParams += `transport_agent: "${filters.transportAgent}" `;
      }

      // transport agent state
      if (filters.transportAgentState) {
        terminalsParams += `transport_state: "${filters.transportAgentState}" `;
      }

      // terminal state
      if (filters.terminalState) {
        terminalsParams += `terminalState: "${filters.terminalState}" `;
      }

      return terminalsParams;
    },

    // makes request to graphql
    async makeGraphQLTerminalRequest(params, query) {
      let requestQuery =
        params.length > 0
          ? `{terminals(${params}) {${query}}}`
          : `{terminals {${query}}}`;
      const result = this.$http
        .post(`${this.$backEndUrl}/v1/proxy/aggregation`, {
          url: `/v2/graphql`,
          method: "POST",
          payload: { query: requestQuery },
          headers: {},
        })
        .then((res) => {
          console.log("Response from aggregation obtained", res);
          if (res.data.payload.errors.length != 0) {
            console.log("Error from aggregation", res.data.payload.errors);
            return;
          }
          return res.data.payload.data;
        })
        .catch((error) => {
          console.log("Error to get terminal data", error);
        });

      return await result;
    },

    // resets page state
    resetPageState() {
      this.totalCount = 0;
      this.currentPage = 1;
      this.hasNextPage = false;
      this.endCursor = null;
      this.startCursor = null;
      this.previosPageEndCursor = null;
      this.forwardPageStartCursor = null;
      this.selectedTerminalId = null;
      this.terminalExtendedInfo = [];
      this.selectedTerminals = [];
      this.selectedAllChecked = false;
    },

    // make basic search
    async makeSearch(filters) {
      this.showTerminalLoad = true;
      // reset search state
      this.resetPageState();
      this.searchFilters = { ...filters };
      let terminalsParams = `first: ${this.terminalsPerPage} `;
      terminalsParams += this.formSearchParamsByFilter(filters);
      // make rquest and extract data
      let terminalRaw = await this.makeGraphQLTerminalRequest(
        terminalsParams,
        this.terminalTableQuery
      );
      if (terminalRaw) {
        this.extractTerminalDataFromGraph(terminalRaw);
        this.previosPageEndCursor = this.endCursor;
      } else {
        this.terminals = [];
      }
      this.showTerminalLoad = false;
    },

    // callback to page forward
    async onPageForward() {
      const tempCursor = this.endCursor;
      let terminalsParams = `first: ${this.terminalsPerPage} after: "${this.endCursor}"`;
      terminalsParams += this.formSearchParamsByFilter(this.searchFilters);
      let terminalRaw = await this.makeGraphQLTerminalRequest(
        terminalsParams,
        this.terminalTableQuery
      );
      if (terminalRaw) {
        this.extractTerminalDataFromGraph(terminalRaw);
        this.currentPage += 1;
        this.previosPageEndCursor = tempCursor;
        this.forwardPageStartCursor = null;
      }
    },

    // callback to page last
    async onPagePrevios() {
      const tempCursor = this.startCursor;
      let terminalsParams = `last: ${this.terminalsPerPage} before: "${this.startCursor}"`;
      terminalsParams += this.formSearchParamsByFilter(this.searchFilters);
      let terminalRaw = await this.makeGraphQLTerminalRequest(
        terminalsParams,
        this.terminalTableQuery
      );
      if (terminalRaw) {
        this.extractTerminalDataFromGraph(terminalRaw);
        this.currentPage -= 1;
        this.forwardPageStartCursor = tempCursor;
        this.previosPageEndCursor = null;
      }
    },

    // callback to page privios
    async onPageLast(lastPage) {
      let terminalsParams = `last: ${this.terminalsPerPage} `;
      terminalsParams += this.formSearchParamsByFilter(this.searchFilters);
      let terminalRaw = await this.makeGraphQLTerminalRequest(
        terminalsParams,
        this.terminalTableQuery
      );
      if (terminalRaw) {
        terminalRaw.terminals.totalCount = this.totalCount;
        terminalRaw.terminals.pageInfo.hasNextPage = false;
        this.extractTerminalDataFromGraph(terminalRaw);
        this.currentPage = lastPage;
      }
    },

    // callback to page privios
    async refreshPage() {
      this.$refs.notifyBar.addWaitMsg({
        overWriteMsg: "Обновление терминалов",
      });
      let terminalsParams = ``;
      if (this.currentPage === 1) {
        terminalsParams = `first: ${this.terminalsPerPage} `;
      } else if (this.previosPageEndCursor) {
        terminalsParams = `first: ${this.terminalsPerPage} after: "${this.previosPageEndCursor}"`;
      } else if (this.forwardPageStartCursor) {
        terminalsParams = `last: ${this.terminalsPerPage} before: "${this.forwardPageStartCursor}"`;
      }

      terminalsParams += this.formSearchParamsByFilter(this.searchFilters);
      let terminalRaw = await this.makeGraphQLTerminalRequest(
        terminalsParams,
        this.terminalTableQuery
      );
      if (terminalRaw) {
        this.extractTerminalDataFromGraph(terminalRaw);
      }
      this.$refs.notifyBar.addOKMsg({
        overWriteMsg: "Терминалы обновлены",
      });
    },

    // callback to select terminal
    onHandleSelectTerminal(terminalId, checked) {
      if (checked) {
        this.selectedTerminals.push(terminalId);
      } else {
        this.selectedTerminals = this.selectedTerminals.filter(
          (e) => e !== terminalId
        );
      }
    },

    // callback to select all terminals
    onHandleSelectTerminalAll(checked) {
      if (checked) {
        this.selectedTerminals = this.terminals.map((el) => el.terminalId);
        this.selectedAllChecked = true;
      } else {
        this.selectedTerminals = [];
        this.selectedAllChecked = false;
      }
    },

    onRestartTerminalContent(terminalId) {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/container/restart?terminalId=${terminalId}&type=restar`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((response) => {
          if (response.data.statusCode !== 200) {
            console.log(
              "Error to restart terminal content",
              response.data.payload
            );
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: `Ошибка restart content: ${response.data.payload.msg}`,
            });
            return;
          }

          console.log(
            "Response from proxy api obtained (restart terminal content). TaskId:",
            response.data.payload.taskId,
            response
          );
          this.$refs.notifyBar.addOKMsg({
            taskId: response.data.payload.taskId,
            overWriteMsg: "Перезагрузка контента выполнена",
          });
        })
        .catch((error) => {
          console.log("Error to restart content", error);
          if (error.response.status === 403) {
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: `Ошибка restart content: Недостаточно прав`,
            });
            return;
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Ошибка перезагрузки контента",
          });
        });
    },

    onSendTerminalNotify(terminalId) {
      this.$refs.notifyBar.addWaitMsg({
        overWriteMsg: "Send notify started",
      });
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/showinfo?terminalId=${terminalId}`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((response) => {
          if (response.data.statusCode !== 200) {
            console.log("Error to send notify terminal", response.data.payload);
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: `Ошибка send notify`,
            });
            return;
          }

          this.$refs.notifyBar.addOKMsg({
            overWriteMsg: "Send notify done",
          });
        })
        .catch((error) => {
          console.log("Error to send notify terminal", error);
          if (error.request.status === 403) {
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: "Недостаточно прав",
            });
            return;
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Send notify",
          });
        });
    },
    onUnregisterTerminall(terminalId) {
      this.$refs.notifyBar.addWaitMsg({
        overWriteMsg: "Отзыв лицензии",
      });
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminals/deny?id=${terminalId}`,
          method: "PUT",
          payload: {},
          headers: {},
        })
        .then((response) => {
          if (response.data.statusCode !== 200) {
            console.log("Error to delete terminal", response.data.payload);
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: `Ошибка отзыв лицензии`,
            });
            return;
          }
          this.$refs.notifyBar.addOKMsg({
            overWriteMsg: "Лицензия отозвана",
          });
          this.updateTerminalData([terminalId], false);
        })
        .catch((error) => {
          console.log("Error to reset cache terminal", error);
          if (error.request.status === 403) {
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: "Недостаточно прав",
            });
            return;
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Ошибка отзыв лицензии",
          });
        });
    },
    onAllowRegisterTerminal(terminalId) {
      this.$refs.notifyBar.addWaitMsg({
        overWriteMsg: "Регистрация терминала",
      });
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminals/unblock?id=${terminalId}`,
          method: "PUT",
          payload: {},
          headers: {},
        })
        .then((response) => {
          if (response.data.statusCode !== 200) {
            console.log("Error to register terminal", response.data.payload);
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: `Ошибка регистрация терминала`,
            });
            return;
          }
          this.$refs.notifyBar.addOKMsg({
            overWriteMsg: "Терминал зарегистрирован",
          });
          this.updateTerminalData([terminalId], false);
        })
        .catch((error) => {
          console.log("Error to reset cache terminal", error);
          if (error.request.status === 403) {
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: "Недостаточно прав",
            });
            return;
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Ошибка регистрация терминала",
          });
        });
    },
    onResetCache(terminalId) {
      this.$refs.notifyBar.addWaitMsg({
        overWriteMsg: "Пересоздание кеша",
      });
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/clearCache?terminalId=${terminalId}`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((response) => {
          if (response.data.statusCode !== 200) {
            console.log("Error to reset cache terminal", response.data.payload);
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: `Ошибка пересоздания кеша`,
            });
            return;
          }

          this.$refs.notifyBar.addOKMsg({
            overWriteMsg: "Кэш будет пересоздан после перезагрузки тк",
          });
        })
        .catch((error) => {
          console.log("Error to reset cache terminal", error);
          if (error.request.status === 403) {
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: "Недостаточно прав",
            });
            return;
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Ошибка пересоздания кеша",
          });
        });
    },

    onRestartTerminal(terminalId) {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/reset?terminalId=${terminalId}`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((response) => {
          if (response.data.statusCode !== 200) {
            console.log("Error to restart terminal", response.data.payload);
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: `Ошибка restart terminal: ${response.data.payload.msg}`,
            });
            return;
          }

          console.log(
            "Response from proxy api obtained (restart terminal). TaskId:",
            response.data.payload.taskId,
            response
          );
          this.$refs.notifyBar.addWaitMsg({
            title: "restart terminal",
            taskId: response.data.payload.taskId,
          });
          this.$http_task
            .post(`${this.$backEndUrl}/v1/proxy/api`, {
              url: `/v2/task?taskId=${response.data.payload.taskId}`,
              method: "GET",
              payload: {},
              headers: {},
            })
            .then((taskResponse) => {
              console.log(
                `Response for taskId: ${response.data.payload.taskId} obtained. State ${taskResponse.data.payload.taskState}`
              );
              if (taskResponse.data.payload.taskState === "OK") {
                this.$refs.notifyBar.addOKMsg({
                  taskId: response.data.payload.taskId,
                  title: "restart terminal",
                });
                return;
              }
              if (taskResponse.data.payload.taskState === "ERROR") {
                this.$refs.notifyBar.addErrorMsg({
                  taskId: response.data.payload.taskId,
                  title: "restart terminal",
                });
              }
            })
            .catch((error) => {
              console.log("Error to get task result", error);
              this.$refs.notifyBar.addErrorMsg({
                taskId: response.data.payload.taskId,
                title: "restart terminal",
              });
            });
        })
        .catch((error) => {
          console.log("Error to restart terminal", error);
          if (error.request.status === 403) {
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: "Недостаточно прав",
            });
            return;
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Ошибка перезагрузки терминала",
          });
        });
    },

    // callback to obtain ssh
    onHandleMakeSSH(terminalId) {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/sshport?terminalId=${terminalId}`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((response) => {
          if (response.data.statusCode !== 200) {
            console.log("Error to create sshport", response.data.payload);
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: `Ошибка ssh: ${response.data.payload.msg}`,
            });
            return;
          }

          console.log(
            "Response from proxy api obtained (create sshport data). TaskId:",
            response.data.payload.taskId,
            response
          );
          this.$refs.notifyBar.addWaitMsg({
            title: "ssh",
            taskId: response.data.payload.taskId,
          });
          this.$http_task
            .post(`${this.$backEndUrl}/v1/proxy/api`, {
              url: `/v2/task?taskId=${response.data.payload.taskId}`,
              method: "GET",
              payload: {},
              headers: {},
            })
            .then((taskResponse) => {
              console.log(
                `Response for taskId: ${response.data.payload.taskId} obtained. State ${taskResponse.data.payload.taskState}`
              );
              if (taskResponse.data.payload.taskState === "OK") {
                this.$refs.notifyBar.addOKMsg({
                  taskId: response.data.payload.taskId,
                  title: "ssh",
                  copyText: `ssh ${sshUser}@${sshHost}  -p ${taskResponse.data.payload.taskData.result}`,
                });
                return;
              }
              if (taskResponse.data.payload.taskState === "ERROR") {
                this.$refs.notifyBar.addErrorMsg({
                  taskId: response.data.payload.taskId,
                  title: "ssh",
                });
              }
            })
            .catch((error) => {
              console.log("Error to get task result", error);
              this.$refs.notifyBar.addErrorMsg({
                taskId: response.data.payload.taskId,
                title: "ssh",
              });
            });
        })
        .catch((error) => {
          console.log("Error to create sshport", error);
          if (error.request.status === 403) {
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: "Недостаточно прав",
            });
            return;
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Ошибка создания ssh",
          });
        });
    },
    async onHandleMakeLogs(terminalId) {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/logs?terminalId=${terminalId}`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((response) => {
          if (response.data.statusCode !== 200) {
            console.log("Error to create logs", response.data.payload);
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: `Ошибка logs: ${response.data.payload.msg}`,
            });
            return;
          }
          console.log(
            "Response from proxy api obtained (create logs data). TaskId:",
            response.data.payload.taskId,
            response
          );
          this.$refs.notifyBar.addWaitMsg({
            title: "logs",
            taskId: response.data.payload.taskId,
          });
          this.$http_task
            .post(`${this.$backEndUrl}/v1/proxy/api`, {
              url: `/v2/task?taskId=${response.data.payload.taskId}`,
              method: "GET",
              payload: {},
              headers: {},
            })
            .then((taskResponse) => {
              console.log(
                `Response for taskId: ${response.data.payload.taskId} obtained. State ${taskResponse.data.payload.taskState}`
              );
              if (taskResponse.data.payload.taskState === "OK") {
                this.$refs.notifyBar.addOKMsg({
                  taskId: response.data.payload.taskId,
                  title: "logs",
                  newTabLink: taskResponse.data.payload.taskData.result,
                });
                return;
              }
              if (taskResponse.data.payload.taskState === "ERROR") {
                this.$refs.notifyBar.addErrorMsg({
                  taskId: response.data.payload.taskId,
                  title: "logs",
                });
              }
            })
            .catch((error) => {
              console.log("Error to get task result", error);
              this.$refs.notifyBar.addErrorMsg({
                taskId: response.data.payload.taskId,
                title: "logs",
              });
            });
        })
        .catch((error) => {
          console.log("Error to create logs", error);
          if (error.request.status === 403) {
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: "Недостаточно прав",
            });
            return;
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Ошибка создания логов",
          });
        });
    },
    async onHandleMakeScreen(terminalId) {
      this.$http
        .post(`${this.$backEndUrl}/v1/proxy/api`, {
          url: `/v2/terminal/screenshots?terminalId=${terminalId}`,
          method: "GET",
          payload: {},
          headers: {},
        })
        .then((response) => {
          if (response.data.statusCode !== 200) {
            console.log("Error to create screenshot", response.data.payload);
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: `Ошибка screenshot: ${response.data.payload.msg}`,
            });
            return;
          }
          console.log(
            "Response from proxy api obtained (create screenshots data). TaskId:",
            response.data.payload.taskId,
            response
          );
          this.$refs.notifyBar.addWaitMsg({
            title: "screenshot",
            taskId: response.data.payload.taskId,
          });
          this.$http_task
            .post(`${this.$backEndUrl}/v1/proxy/api`, {
              url: `/v2/task?taskId=${response.data.payload.taskId}`,
              method: "GET",
              payload: {},
              headers: {},
            })
            .then((taskResponse) => {
              console.log(
                `Response for taskId: ${response.data.payload.taskId} obtained. State ${taskResponse.data.payload.taskState}`
              );
              if (taskResponse.data.payload.taskState === "OK") {
                this.$refs.notifyBar.addOKMsg({
                  taskId: response.data.payload.taskId,
                  title: "screenshot",
                  newTabLink: taskResponse.data.payload.taskData.result,
                });
                return;
              }
              if (taskResponse.data.payload.taskState === "ERROR") {
                this.$refs.notifyBar.addErrorMsg({
                  taskId: response.data.payload.taskId,
                  title: "screenshot",
                });
              }
            })
            .catch((error) => {
              console.log("Error to get task result", error);
              this.$refs.notifyBar.addErrorMsg({
                taskId: response.data.payload.taskId,
                title: "screenshot",
              });
            });
        })
        .catch((error) => {
          console.log("Error to create screen", error);
          if (error.request.status === 403) {
            this.$refs.notifyBar.addErrorMsg({
              overWriteMsg: "Недостаточно прав",
            });
            return;
          }
          this.$refs.notifyBar.addErrorMsg({
            overWriteMsg: "Ошибка создания скриншота",
          });
        });
    },

    unpackExtendedTerminalInfoGraphQL(terminal) {
      terminal.devices.inputDevices = this.unpackRelyEdges(
        terminal.devices.inputDevices
      );
      terminal.devices.touchDevices = this.unpackRelyEdges(
        terminal.devices.touchDevices
      );
      terminal.devices.storages = this.unpackRelyEdges(
        terminal.devices.storages
      );
      terminal.devices.storages.forEach((el) => {
        el.partitions = this.unpackRelyEdges(el.partitions);
      });
      terminal.displays.outputs = this.unpackRelyEdges(
        terminal.displays.outputs
      );
      terminal.networks = this.unpackRelyEdges(terminal.networks);
      terminal.networks.forEach((el) => {
        el.ipAddr = this.unpackRelyEdges(el.ipAddr);
      });
      terminal.distributions = this.unpackRelyEdges(terminal.distributions);
      return terminal;
    },

    async onHandleSelectTerminalClick(terminalId) {
      // check in cache
      if (this.terminalExtendedInfo[terminalId] !== undefined) {
        this.selectedTerminalId = terminalId;
        return;
      }

      // get data
      const params = `first: 1 terminalId: ${terminalId}`;
      let terminal = await this.makeGraphQLTerminalRequest(
        params,
        this.terminalPanelQuery
      );

      // unpack data
      terminal = this.unpackRelyEdges(terminal.terminals)[0];
      terminal = this.unpackExtendedTerminalInfoGraphQL(terminal);

      // set data
      this.terminalExtendedInfo[terminalId] = terminal;
      this.selectedTerminalId = terminalId;
    },
  },

  mounted() {
    this.getFilterData();
  },
};
</script>

<style lang="less">
#terminals_page {
  overflow-y: scroll;
  overflow-x: hidden;
  max-height: calc(100vh - 100px);
  margin-right: -20px;
  padding-right: 10px;

  // &::-webkit-scrollbar {
  //   display: none;
  // }
  .terminal_title {
    margin-left: 20px;

    font-family: Roboto;
    font-size: 20px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 20px;
  }

  .terminals_load_container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50vh;

    .loader {
      width: 30%;
      height: 2.8px;
      display: inline-block;
      position: relative;
      background: #4250b74d;
      border-radius: 15px;

      overflow: hidden;
    }
    .loader::after {
      content: "";
      width: 192px;
      height: 4.8px;
      background: #4150b7;
      position: absolute;
      box-sizing: border-box;

      animation: animloader 2s linear infinite;
    }
    @keyframes animloader {
      0% {
        left: 0;
        transform: translateX(-100%);
      }
      100% {
        left: 100%;
        transform: translateX(0%);
      }
    }
  }
}

.terminal-panel-enter-active {
  opacity: 1;
  transition: opacity 0.15s ease-in-out;
}
.terminal-panel-leave-active {
  transition: all 0.15s ease-in-out;
}

.terminal-panel-enter-from {
  opacity: 0;
}
.terminal-panel-enter-to {
  opacity: 1;
}
.terminal-panel-leave-from {
  opacity: 1;
}
.terminal-panel-leave-to {
  opacity: 0;
}

.terminal-table-enter-active {
  opacity: 1;
  transition: all 0.6s ease-in;
}
.terminal-table-leave-active {
  transition: all 0.15s ease-out;
}

.terminal-table-enter-from {
  opacity: 0;
}
.terminal-table-enter-to {
  opacity: 1;
  // transform: scale(1.1);
}
.terminal-table-leave-from {
  opacity: 1;
}
.terminal-table-leave-to {
  opacity: 0;
}
</style>
