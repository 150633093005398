<template>
  <div id="page_navigation">
    <div class="page_navigation_root_container">
      <div class="page_navigation_root_container__item page_count_container">
        <div class="page_count_container__item title">
          <label>Кол-во:</label>
        </div>
        <div class="page_count_container__item text_input">
          <input
            @change="onPerPage"
            type="number"
            min="1"
            :placeholder="pageStep"
          />
        </div>
        <div
          class="page_count_container__item count_button"
          :class="!anySelectTerminal ? 'disable_button' : ''"
        >
          <button
            :disabled="!anySelectTerminal"
            title="Обновить"
            @click="$emit('updateSelectedTerminals')"
          >
            <svg
              width="12"
              height="11"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.00065 13.6673C5.13954 13.6673 3.56315 13.0215 2.27148 11.7298C0.979818 10.4382 0.333984 8.86176 0.333984 7.00065C0.333984 5.13954 0.979818 3.56315 2.27148 2.27148C3.56315 0.979818 5.13954 0.333984 7.00065 0.333984C7.95898 0.333984 8.87565 0.53204 9.75065 0.928151C10.6257 1.32371 11.3757 1.88954 12.0007 2.62565V0.333984H13.6673V6.16732H7.83398V4.50065H11.334C10.8895 3.72287 10.282 3.11176 9.51148 2.66732C8.74037 2.22287 7.90343 2.00065 7.00065 2.00065C5.61176 2.00065 4.43121 2.48676 3.45898 3.45898C2.48676 4.43121 2.00065 5.61176 2.00065 7.00065C2.00065 8.38954 2.48676 9.5701 3.45898 10.5423C4.43121 11.5145 5.61176 12.0007 7.00065 12.0007C8.0701 12.0007 9.03537 11.6951 9.89648 11.084C10.7576 10.4729 11.3618 9.66732 11.709 8.66732H13.459C13.0701 10.1395 12.2784 11.3409 11.084 12.2715C9.88954 13.202 8.52843 13.6673 7.00065 13.6673Z"
                :fill="!anySelectTerminal ? '#a1a3ab' : '#010101'"
              />
            </svg>
          </button>
        </div>
        <div
          class="page_count_container__item count_button"
          :class="!anySelectTerminal ? 'disable_button' : ''"
        >
          <button
            :disabled="!anySelectTerminal"
            title="Экспорт"
            @click="$emit('exportSelectedTerminals')"
          >
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.00065 0.833984L3.66732 4.16732H6.16732V11.6673H7.83398V4.16732H10.334M12.0007 19.1673H2.00065C1.55862 19.1673 1.1347 18.9917 0.82214 18.6792C0.509579 18.3666 0.333984 17.9427 0.333984 17.5006V7.50065C0.333984 7.05862 0.509579 6.6347 0.82214 6.32214C1.1347 6.00958 1.55862 5.83398 2.00065 5.83398H4.50065V7.50065H2.00065V17.5006H12.0007V7.50065H9.50065V5.83398H12.0007C12.4427 5.83398 12.8666 6.00958 13.1792 6.32214C13.4917 6.6347 13.6673 7.05862 13.6673 7.50065V17.5006C13.6673 17.9427 13.4917 18.3666 13.1792 18.6792C12.8666 18.9917 12.4427 19.1673 12.0007 19.1673Z"
                :fill="!anySelectTerminal ? '#a1a3ab' : '#010101'"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        class="page_navigation_root_container__item page_navigation_container"
      >
        <div class="page_navigation_container__item title_page_container">
          <div class="title_page_container__item title">
            <label>Найдено</label>
          </div>
          <div class="title_page_container__item title title_count">
            <label>{{ totalCount }}</label>
          </div>
        </div>
        <div class="page_navigation_container__item navigation_container">
          <div class="navigation_container__item nav_button page_back_to_first">
            <button
              @click="currentPage > 1 ? backToFirstPage() : null"
              :class="currentPage === 1 ? 'nav_button_disable' : ''"
            >
              <svg
                v-for="index in 2"
                :key="index"
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.254229 5.20712L4.33756 9.29045L5.16252 8.4655L1.49167 4.79464L5.16252 1.12379L4.33756 0.298828L0.254229 4.38216C0.0264225 4.60997 0.0264225 4.97931 0.254229 5.20712Z"
                  :fill="currentPage > 1 ? '#2B2D33' : '#a1a3ab'"
                />
              </svg>
            </button>
          </div>
          <div class="navigation_container__item nav_button page_back_button">
            <button
              @click="previousPageNumber ? pageBack() : null"
              :class="!previousPageNumber ? 'nav_button_disable' : ''"
            >
              <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.254229 5.20712L4.33756 9.29045L5.16252 8.4655L1.49167 4.79464L5.16252 1.12379L4.33756 0.298828L0.254229 4.38216C0.0264225 4.60997 0.0264225 4.97931 0.254229 5.20712Z"
                  :fill="previousPageNumber ? '#2B2D33' : '#a1a3ab'"
                />
              </svg>
            </button>
          </div>
          <div class="navigation_container__item page_button_container">
            <div
              v-if="currentPage > 2"
              class="page_button_container__item page_dotted"
            >
              ...
            </div>
            <div
              v-if="previousPageNumber"
              @click="pageBack"
              class="page_navigation_container__item page_button"
            >
              <button :title="previousPageNumber">
                {{ previousPageNumber }}
              </button>
            </div>
            <div
              class="page_button_container__item page_button page_button_selected"
            >
              <button :title="currentPage">{{ currentPage }}</button>
            </div>
            <div
              v-if="nextPageNumber"
              @click="pageForward"
              class="page_navigation_container__item page_button"
            >
              <button :title="nextPageNumber">{{ nextPageNumber }}</button>
            </div>
            <div
              v-if="nextPageNumber && nextPageNumber != lastPageNumber"
              class="page_button_container__item page_dotted"
            >
              ...
            </div>
          </div>

          <div
            class="navigation_container__item nav_button page_forward_button"
          >
            <button
              @click="nextPageNumber ? pageForward() : null"
              :class="!nextPageNumber ? 'nav_button_disable' : ''"
            >
              <svg
                width="6"
                height="10"
                viewBox="0 0 6 10"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.57492 5.20712L1.49158 9.29045L0.666626 8.4655L4.33748 4.79464L0.666626 1.12379L1.49158 0.298828L5.57492 4.38216C5.80272 4.60997 5.80272 4.97931 5.57492 5.20712Z"
                  :fill="nextPageNumber ? '#2B2D33' : '#a1a3ab'"
                />
              </svg>
            </button>
          </div>
        </div>

        <!-- <div @click="forwardToLastPage"  class="page_navigation_container__item nav_button page_forward_last_button">
      <button>
          <svg v-for="index in 2" :key="index" width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M5.57492 5.20712L1.49158 9.29045L0.666626 8.4655L4.33748 4.79464L0.666626 1.12379L1.49158 0.298828L5.57492 4.38216C5.80272 4.60997 5.80272 4.97931 5.57492 5.20712Z" fill="#2B2D33"/>
          </svg>
      </button>
    </div> -->
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "PageNavigation",
  props: {
    // toatl number of terminals
    totalCount: {
      type: Number,
      default() {
        return 0;
      },
    },
    pageStep: {
      type: Number,
      default() {
        return 10;
      },
    },
    hasNextPage: {
      type: Boolean,
      default() {
        return true;
      },
    },
    currentPage: {
      type: Number,
      default() {
        return 1;
      },
    },
    anySelectTerminal: {
      type: Boolean,
      default() {
        return false;
      },
    },
  },
  data() {
    return {
      // currentPage: 1,
    };
  },
  methods: {
    // changes page number
    onPerPage(event) {
      this.$emit("pageNavigationPerPage", Number(event.target.value));
    },

    // move to previos page
    pageBack() {
      if (this.currentPage > 1) {
        this.$emit("pageNavigationPreviousPage");
      }
    },

    // move to previos page
    pageForward() {
      if (this.hasNextPage) {
        this.$emit("pageNavigationNextPage");
      }
    },

    // back to first page
    backToFirstPage() {
      this.$emit("pageNavigationFirstPage");
    },
    forwardToLastPage() {
      this.$emit("pageNavigationLastPage", this.lastPageNumber);
    },
  },
  computed: {
    lastPageNumber() {
      return this.totalCount < this.pageStep
        ? 1
        : Math.ceil(this.totalCount / this.pageStep);
    },
    // calculate previous page
    previousPageNumber() {
      if (this.currentPage > 1) {
        return this.currentPage - 1;
      }
      return null;
    },

    // calculate next page
    nextPageNumber() {
      if (this.currentPage + 1 <= this.lastPageNumber) {
        return this.currentPage + 1;
      }
      return null;
    },
  },
};
</script>

<style lang="less">
#page_navigation {
  .page_navigation_root_container {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 10px;

    @media (max-width: 500px) {
      flex-direction: column;
    }
    @media (min-width: 501px) {
      flex-direction: row;
    }
  }

  .page_count_container {
    display: flex;
    margin-left: 20px;
    height: 26px;
    width: 170px;
    justify-content: space-between;
    align-items: center;

    .disable_button {
      button {
        &:hover {
          background-color: unset;
          border-radius: unset;
        }
      }
    }

    button {
      all: unset;
      text-align: center;
      height: 20px;
      width: 20px;
      padding: 2px;

      &:hover {
        background-color: #4151b746;
        border-radius: 50%;
        transition: 0.7s;
      }
    }

    .title {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
    }

    .text_input {
      input {
        width: 30px;
        border: 1px solid #c9c9c9;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        margin-left: 10px;
        padding: 5px 4px 5px 4px;
        text-align: center;

        &:focus::placeholder {
          color: transparent;
        }
      }
    }
  }

  .page_navigation_container {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    margin-top: 17px;
    height: 26px;
    margin-top: 32px;

    @media (max-width: 500px) {
      align-items: flex-start;
    }

    @media (min-width: 501px) {
      align-items: center;
    }

    .title_page_container {
      display: flex;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      margin-right: 25px;

      .title_count {
        margin-left: 5px;
      }
    }
  }

  .navigation_container {
    display: flex;
    align-items: center;
    width: 250px;

    @media (max-width: 500px) {
      margin-left: 0px;
      width: 100%;
    }

    @media (min-width: 501px) {
      margin-left: 25px;
      width: 250px;
    }

    .navigation_container__item {
      &.title {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        margin-right: 14.04px;
      }

      &.page_back_button {
        margin-left: 14.04px;
        width: 30px;
      }

      &.nav_button {
        .nav_button_disable {
          text-align: center;
          cursor: unset;
          background: unset;

          &:hover {
            cursor: unset;
            background: unset;
          }
        }

        button {
          all: unset;
          padding: 3px;
          width: 20px;
          height: 20px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:hover {
            background-color: #4151b746;
            border-radius: 50%;
            transition: 0.7s;
          }
        }

        &.page_forward_last_button {
          margin-left: 14.04px;
          cursor: pointer;
        }
      }

      &.page_dotted {
        width: 30px;
      }

      &.page_forward_button {
        width: 30px;
        cursor: pointer;
      }
    }

    .page_button_container {
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      padding: 0px 20px;

      @media (max-width: 500px) {
        justify-content: space-between;
      }

      @media (min-width: 501px) {
        width: 100px;
      }

      .page_button {
        font-family: "Roboto";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 26px;

        button {
          all: unset;
          width: 27px;
          height: 26px;
          border-radius: 4px;
          text-align: center;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;

          &:hover {
            background-color: #4151b746;
            transition: 0.7s;
            width: 26px;
            height: 25px;
          }
        }

        &.page_button_selected {
          button {
            animation-duration: 3s;
            animation-name: slidein;
            background: #f1f6ff;
            border-radius: 4px;
            text-align: center;
            transition: 0.7s;
          }
          
        }
      }
    }
  }
}
</style>
