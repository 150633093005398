<template>
  <div id="terminal_panel">
    <div class="terminal_info_container">
      <div class="terminal_info_container__item header_container">
        <div class="header_container__item title">
          <label>Terminal {{ terminal.terminalId }}</label>
        </div>
        <div class="header_container__item center_buttons_container">
          <div
            class="center_buttons_container__item"
            :class="selectedTab === 'device' ? 'selected_tab' : ''"
          >
            <button id="device" @click="onSelectTab" title="Информация">
              <svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.16699 5.99935H9.83366V4.33268H8.16699M9.00033 15.166C5.32533 15.166 2.33366 12.1743 2.33366 8.49935C2.33366 4.82435 5.32533 1.83268 9.00033 1.83268C12.6753 1.83268 15.667 4.82435 15.667 8.49935C15.667 12.1743 12.6753 15.166 9.00033 15.166ZM9.00033 0.166016C7.90598 0.166016 6.82234 0.381564 5.8113 0.800353C4.80025 1.21914 3.88159 1.83297 3.10777 2.60679C1.54497 4.1696 0.666992 6.28921 0.666992 8.49935C0.666992 10.7095 1.54497 12.8291 3.10777 14.3919C3.88159 15.1657 4.80025 15.7796 5.8113 16.1983C6.82234 16.6171 7.90598 16.8327 9.00033 16.8327C11.2105 16.8327 13.3301 15.9547 14.8929 14.3919C16.4557 12.8291 17.3337 10.7095 17.3337 8.49935C17.3337 7.405 17.1181 6.32137 16.6993 5.31032C16.2805 4.29927 15.6667 3.38061 14.8929 2.60679C14.1191 1.83297 13.2004 1.21914 12.1894 0.800353C11.1783 0.381564 10.0947 0.166016 9.00033 0.166016ZM8.16699 12.666H9.83366V7.66602H8.16699V12.666Z"
                  fill="#010101"
                />
              </svg>
            </button>
          </div>
          <div
            class="center_buttons_container__item"
            :class="selectedTab === 'history' ? 'selected_tab' : ''"
          >
            <button id="history" @click="onSelectTab" title="История">
              <svg
                width="24"
                height="15"
                viewBox="0 0 15 15"
                transform="translate(-3 0)"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.8335 0C8.84437 0 6.93672 0.790176 5.53019 2.1967C4.12367 3.60322 3.3335 5.51088 3.3335 7.5H0.833496L4.07516 10.7417L4.1335 10.8583L7.50016 7.5H5.00016C5.00016 4.275 7.6085 1.66667 10.8335 1.66667C14.0585 1.66667 16.6668 4.275 16.6668 7.5C16.6668 10.725 14.0585 13.3333 10.8335 13.3333C9.22516 13.3333 7.76683 12.675 6.71683 11.6167L5.5335 12.8C6.22801 13.4983 7.0539 14.0523 7.96355 14.4299C8.8732 14.8075 9.8486 15.0012 10.8335 15C12.8226 15 14.7303 14.2098 16.1368 12.8033C17.5433 11.3968 18.3335 9.48912 18.3335 7.5C18.3335 5.51088 17.5433 3.60322 16.1368 2.1967C14.7303 0.790176 12.8226 2.96403e-08 10.8335 0ZM10.0002 4.16667V8.33333L13.5668 10.45L14.1668 9.44167L11.2502 7.70833V4.16667H10.0002Z"
                  fill="#010101"
                />
              </svg>
            </button>
          </div>
          <div
            class="center_buttons_container__item"
            :class="selectedTab === 'screenshot' ? 'selected_tab' : ''"
          >
            <button id="screenshot" @click="onSelectTab" title="Скриншоты">
              <svg
                width="18"
                height="15"
                viewBox="0 0 18 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8.99984 12.0833C10.0415 12.0833 10.9271 11.7189 11.6565 10.99C12.3854 10.2606 12.7498 9.375 12.7498 8.33333C12.7498 7.29167 12.3854 6.40611 11.6565 5.67667C10.9271 4.94778 10.0415 4.58333 8.99984 4.58333C7.95817 4.58333 7.07262 4.94778 6.34317 5.67667C5.61428 6.40611 5.24984 7.29167 5.24984 8.33333C5.24984 9.375 5.61428 10.2606 6.34317 10.99C7.07262 11.7189 7.95817 12.0833 8.99984 12.0833ZM8.99984 10.4167C8.4165 10.4167 7.92345 10.2153 7.52067 9.8125C7.11789 9.40972 6.9165 8.91667 6.9165 8.33333C6.9165 7.75 7.11789 7.25694 7.52067 6.85417C7.92345 6.45139 8.4165 6.25 8.99984 6.25C9.58317 6.25 10.0762 6.45139 10.479 6.85417C10.8818 7.25694 11.0832 7.75 11.0832 8.33333C11.0832 8.91667 10.8818 9.40972 10.479 9.8125C10.0762 10.2153 9.58317 10.4167 8.99984 10.4167ZM2.33317 15C1.87484 15 1.48262 14.8369 1.1565 14.5108C0.829837 14.1842 0.666504 13.7917 0.666504 13.3333V3.33333C0.666504 2.875 0.829837 2.48278 1.1565 2.15667C1.48262 1.83 1.87484 1.66667 2.33317 1.66667H4.95817L6.49984 0H11.4998L13.0415 1.66667H15.6665C16.1248 1.66667 16.5173 1.83 16.844 2.15667C17.1701 2.48278 17.3332 2.875 17.3332 3.33333V13.3333C17.3332 13.7917 17.1701 14.1842 16.844 14.5108C16.5173 14.8369 16.1248 15 15.6665 15H2.33317ZM15.6665 13.3333V3.33333H12.2915L10.7707 1.66667H7.229L5.70817 3.33333H2.33317V13.3333H15.6665Z"
                  fill="#010101"
                />
              </svg>
            </button>
          </div>
          <div
            class="center_buttons_container__item"
            :class="selectedTab === 'log' ? 'selected_tab' : ''"
          >
            <button id="log" @click="onSelectTab" title="Логи">
              <svg
                width="14"
                height="17"
                viewBox="0 0 14 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M2.00016 0.167969C1.55814 0.167969 1.13421 0.343563 0.821651 0.656124C0.509091 0.968685 0.333496 1.39261 0.333496 1.83464V15.168C0.333496 15.61 0.509091 16.0339 0.821651 16.3465C1.13421 16.659 1.55814 16.8346 2.00016 16.8346H12.0002C12.4422 16.8346 12.8661 16.659 13.1787 16.3465C13.4912 16.0339 13.6668 15.61 13.6668 15.168V5.16797L8.66683 0.167969H2.00016ZM2.00016 1.83464H7.8335V6.0013H12.0002V15.168H2.00016V1.83464ZM3.66683 8.5013V10.168H10.3335V8.5013H3.66683ZM3.66683 11.8346V13.5013H7.8335V11.8346H3.66683Z"
                  fill="#010101"
                />
              </svg>
            </button>
          </div>
          <div
            class="center_buttons_container__item"
            :class="selectedTab === 'record' ? 'selected_tab' : ''"
          >
            <button id="record" @click="onSelectTab" title="Записи экрана">
              <svg
                width="20"
                height="15"
                viewBox="0 0 20 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.5002 0H2.50016C1.57516 0 0.833496 0.741667 0.833496 1.66667V11.6667C0.833496 12.1087 1.00909 12.5326 1.32165 12.8452C1.63421 13.1577 2.05814 13.3333 2.50016 13.3333H6.66683V15H13.3335V13.3333H17.5002C18.4168 13.3333 19.1585 12.5833 19.1585 11.6667L19.1668 1.66667C19.1668 1.22464 18.9912 0.800716 18.6787 0.488155C18.3661 0.175595 17.9422 0 17.5002 0ZM17.5002 11.6667H2.50016V1.66667H17.5002V11.6667ZM13.3335 6.66667L7.50016 10V3.33333L13.3335 6.66667Z"
                  fill="#010101"
                />
              </svg>
            </button>
          </div>
          <div
            class="center_buttons_container__item"
            :class="selectedTab === 'chrome' ? 'selected_tab' : ''"
          >
            <button id="chrome" @click="onSelectTab" title="Chromium debug">
              <svg
                width="18"
                height="17"
                viewBox="0 0 18 17"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.189 16.6371C3.45817 15.8105 0.666504 12.4821 0.666504 8.50131C0.666504 6.98297 1.07234 5.55964 1.78234 4.33297L5.3515 10.5155C5.7525 11.2422 6.36146 11.8327 7.10028 12.211C7.8391 12.5893 8.6741 12.7383 9.49817 12.6388L7.18984 16.6371H7.189ZM8.99984 16.8346L12.5723 10.6471C12.9623 9.99939 13.1677 9.25737 13.1665 8.50131C13.1678 7.59955 12.8753 6.72192 12.3332 6.00131H16.9515C17.2054 6.81031 17.3341 7.65339 17.3332 8.50131C17.3332 13.1038 13.6023 16.8346 8.99984 16.8346ZM11.1432 9.78881C10.9192 10.1621 10.6017 10.4704 10.222 10.6833C9.84228 10.8961 9.41356 11.0061 8.97828 11.0023C8.543 10.9985 8.11626 10.8811 7.7403 10.6617C7.36435 10.4422 7.05223 10.1284 6.83484 9.75131L6.81067 9.70964C6.59965 9.32723 6.49249 8.89621 6.49987 8.45951C6.50725 8.0228 6.62892 7.59565 6.85275 7.22059C7.07658 6.84553 7.39476 6.53566 7.77561 6.32184C8.15646 6.10801 8.58669 5.9977 9.02343 6.00188C9.46018 6.00606 9.88822 6.12458 10.2649 6.34566C10.6416 6.56673 10.9538 6.88264 11.1704 7.26191C11.387 7.64118 11.5005 8.07058 11.4995 8.50735C11.4985 8.94412 11.3831 9.37301 11.1648 9.75131L11.1432 9.78881ZM2.85984 2.86714C3.63962 2.01538 4.58833 1.33546 5.64549 0.870731C6.70265 0.406001 7.84504 0.166662 8.99984 0.167974C10.463 0.167381 11.9004 0.552169 13.1676 1.28362C14.4347 2.01507 15.4869 3.06737 16.2182 4.33464H8.99984C8.18353 4.33454 7.38516 4.57417 6.70384 5.02379C6.02252 5.47341 5.48825 6.11322 5.16734 6.86381L2.85984 2.86714Z"
                  fill="#010101"
                />
              </svg>
            </button>
          </div>
          <div
            class="center_buttons_container__item"
            :class="selectedTab === 'ssh' ? 'selected_tab' : ''"
          >
            <button id="ssh" @click="onSelectTab" title="SSH">
              <svg
                width="19"
                height="16"
                viewBox="0 0 19 7"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.68766 0.375C1.06266 0.375 0.645996 0.895833 0.645996 1.41667V3.23958C0.645996 3.76042 1.06266 4.28125 1.68766 4.28125H4.29183V5.0625H0.645996V6.625H4.81266C5.43766 6.625 5.85433 6.10417 5.85433 5.58333V3.76042C5.85433 3.23958 5.43766 2.71875 4.81266 2.71875H2.2085V1.9375H5.85433V0.375H1.68766ZM7.93766 0.375C7.31266 0.375 6.896 0.895833 6.896 1.41667V3.23958C6.896 3.76042 7.31266 4.28125 7.93766 4.28125H10.5418V5.0625H6.896V6.625H11.0627C11.6877 6.625 12.1043 6.10417 12.1043 5.58333V3.76042C12.1043 3.23958 11.6877 2.71875 11.0627 2.71875H8.4585V1.9375H12.1043V0.375H7.93766ZM13.146 0.375V6.625H14.7085V4.02083H16.7918V6.625H18.3543V0.375H16.7918V2.45833H14.7085V0.375H13.146Z"
                  fill="#010101"
                />
              </svg>
            </button>
          </div>
          <div
            class="center_buttons_container__item"
            :class="selectedTab === 'postmortem' ? 'selected_tab' : ''"
          >
            <button id="postmortem" @click="onSelectTab" title="Postmortem">
              <svg fill="#000000" height="64px" width="64px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="-32.85 -32.85 187.37 187.37" xml:space="preserve" stroke="#000000" stroke-width="3.163394"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M60.835,121.669c-33.544,0-60.834-27.29-60.834-60.835C0.001,27.29,27.291,0,60.835,0s60.834,27.29,60.834,60.834 C121.669,94.379,94.379,121.669,60.835,121.669z M60.835,6C30.6,6,6.001,30.599,6.001,60.834c0,30.236,24.599,54.835,54.834,54.835 s54.834-24.599,54.834-54.835C115.669,30.599,91.071,6,60.835,6z"></path> <path d="M78.844,97.437h-6c0-5.642-5.477-10.231-12.208-10.231s-12.208,4.59-12.208,10.231h-6c0-8.95,8.168-16.231,18.208-16.231 S78.844,88.486,78.844,97.437z"></path> <polygon points="98.363,45.285 94.119,41.042 88.006,47.155 81.892,41.042 77.65,45.285 83.763,51.398 77.65,57.512 81.892,61.755 88.006,55.642 94.119,61.755 98.363,57.512 92.249,51.398 "></polygon> <polygon points="43.622,45.285 39.379,41.042 33.266,47.155 27.151,41.042 22.908,45.285 29.022,51.398 22.908,57.512 27.151,61.755 33.266,55.642 39.379,61.755 43.622,57.512 37.509,51.398 "></polygon> </g> </g></svg>
              <!-- <svg fill="#000000" height="64px" width="64px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 121.669 121.669" xml:space="preserve" transform="scale(1 0.5)"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path d="M60.835,121.669c-33.544,0-60.834-27.29-60.834-60.835C0.001,27.29,27.291,0,60.835,0s60.834,27.29,60.834,60.834 C121.669,94.379,94.379,121.669,60.835,121.669z M60.835,6C30.6,6,6.001,30.599,6.001,60.834c0,30.236,24.599,54.835,54.834,54.835 s54.834-24.599,54.834-54.835C115.669,30.599,91.071,6,60.835,6z"></path> <path d="M78.844,97.437h-6c0-5.642-5.477-10.231-12.208-10.231s-12.208,4.59-12.208,10.231h-6c0-8.95,8.168-16.231,18.208-16.231 S78.844,88.486,78.844,97.437z"></path> <polygon points="98.363,45.285 94.119,41.042 88.006,47.155 81.892,41.042 77.65,45.285 83.763,51.398 77.65,57.512 81.892,61.755 88.006,55.642 94.119,61.755 98.363,57.512 92.249,51.398 "></polygon> <polygon points="43.622,45.285 39.379,41.042 33.266,47.155 27.151,41.042 22.908,45.285 29.022,51.398 22.908,57.512 27.151,61.755 33.266,55.642 39.379,61.755 43.622,57.512 37.509,51.398 "></polygon> </g> </g></svg> -->
            </button>
          </div>
        </div>
        <div class="header_container__item left_buttons_container">
          <div class="left_buttons_container__item">
            <button
              @click="$emit('updateTerminalData', terminal.terminalId, true)"
              title="Обновить"
            >
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.00016 13.6673C5.13905 13.6673 3.56266 13.0215 2.271 11.7298C0.979329 10.4382 0.333496 8.86176 0.333496 7.00065C0.333496 5.13954 0.979329 3.56315 2.271 2.27148C3.56266 0.979818 5.13905 0.333984 7.00016 0.333984C7.9585 0.333984 8.87516 0.53204 9.75016 0.928151C10.6252 1.32371 11.3752 1.88954 12.0002 2.62565V0.333984H13.6668V6.16732H7.8335V4.50065H11.3335C10.8891 3.72287 10.2816 3.11176 9.511 2.66732C8.73988 2.22287 7.90294 2.00065 7.00016 2.00065C5.61127 2.00065 4.43072 2.48676 3.4585 3.45898C2.48627 4.43121 2.00016 5.61176 2.00016 7.00065C2.00016 8.38954 2.48627 9.5701 3.4585 10.5423C4.43072 11.5145 5.61127 12.0007 7.00016 12.0007C8.06961 12.0007 9.03489 11.6951 9.896 11.084C10.7571 10.4729 11.3613 9.66732 11.7085 8.66732H13.4585C13.0696 10.1395 12.2779 11.3409 11.0835 12.2715C9.88905 13.202 8.52794 13.6673 7.00016 13.6673Z"
                  fill="#010101"
                />
              </svg>
            </button>
          </div>
          <div class="left_buttons_container__item">
            <button title="Экспорт" @click="downloadTerminal">
              <svg
                width="14"
                height="18"
                viewBox="0 0 14 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.00016 0.833984L3.66683 4.16732H6.16683V11.6673H7.8335V4.16732H10.3335M12.0002 19.1673H2.00016C1.55814 19.1673 1.13421 18.9917 0.821651 18.6792C0.509091 18.3666 0.333496 17.9427 0.333496 17.5006V7.50065C0.333496 7.05862 0.509091 6.6347 0.821651 6.32214C1.13421 6.00958 1.55814 5.83398 2.00016 5.83398H4.50016V7.50065H2.00016V17.5006H12.0002V7.50065H9.50016V5.83398H12.0002C12.4422 5.83398 12.8661 6.00958 13.1787 6.32214C13.4912 6.6347 13.6668 7.05862 13.6668 7.50065V17.5006C13.6668 17.9427 13.4912 18.3666 13.1787 18.6792C12.8661 18.9917 12.4422 19.1673 12.0002 19.1673Z"
                  fill="#010101"
                />
              </svg>
            </button>
          </div>
          <div class="left_buttons_container__item">
            <button @click="$emit('closeTeminalPanel')">
              <svg
                width="12"
                height="12"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.33317 11.8346L0.166504 10.668L4.83317 6.0013L0.166504 1.33464L1.33317 0.167969L5.99984 4.83464L10.6665 0.167969L11.8332 1.33464L7.1665 6.0013L11.8332 10.668L10.6665 11.8346L5.99984 7.16797L1.33317 11.8346Z"
                  fill="#010101"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div class="terminal_info_container__item terminal_panel_tab">
        <Transition name="base" mode="out-in">
          <device
            :terminal="terminal"
            v-if="selectedTab === 'device'"
            @update-terminal-data="
              $emit('updateTerminalData', terminal.terminalId)
            "
          />
          <history
            :terminalId="terminal.terminalId"
            v-else-if="selectedTab === 'history'"
          />
          <screenshot
            :terminalId="terminal.terminalId"
            :online="terminal.online"
            v-else-if="selectedTab === 'screenshot'"
          />
          <log
            :terminalId="terminal.terminalId"
            :online="terminal.online"
            v-else-if="selectedTab === 'log'"
          />
          <record
            :terminalId="terminal.terminalId"
            :online="terminal.online"
            v-else-if="selectedTab === 'record'"
          />
          <chrome
            :terminalId="terminal.terminalId"
            :online="terminal.online"
            v-else-if="selectedTab === 'chrome'"
          />
          <ssh
            :terminalId="terminal.terminalId"
            :online="terminal.online"
            v-else-if="selectedTab === 'ssh'"
          />
          <postmortem
            :terminalId="terminal.terminalId"
            :online="terminal.online"
            v-else-if="selectedTab === 'postmortem'"
          />
        </Transition>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

export default {
  components: {
    device: defineAsyncComponent(() =>
      import("@/components/TerminalDevice.vue")
    ),
    history: defineAsyncComponent(() =>
      import("@/components/TerminalHistory.vue")
    ),
    screenshot: defineAsyncComponent(() =>
      import("@/components/TerminalScreenshot.vue")
    ),
    log: defineAsyncComponent(() => import("@/components/TerminalLog.vue")),
    record: defineAsyncComponent(() =>
      import("@/components/TerminalRecord.vue")
    ),
    chrome: defineAsyncComponent(() =>
      import("@/components/TerminalChrome.vue")
    ),
    ssh: defineAsyncComponent(() => import("@/components/TerminalSSH.vue")),
    postmortem: defineAsyncComponent(() => import("@/components/TerminalPostmortem.vue")),
  },
  props: {
    terminal: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      selectedTab: "device",
    };
  },
  methods: {
    downloadTerminal() {
      var dataStr =
        "data:text/json;charset=utf-8," +
        encodeURIComponent(JSON.stringify(this.terminal, null, 2));
      var downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute(
        "download",
        `terminal_${this.terminal.terminalId}_export` + ".json"
      );
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    },
    onSelectTab(event) {
      this.selectedTab = event.currentTarget.id;
    },
    resetState() {
      this.selectedTab = "device";
    },
  },
  watch: {
    "terminal.terminalId"() {
      this.resetState();
    },
  },
};
</script>

<style lang="less">
@import "../assets/styles/inputs.less";
@import "../assets/styles/buttons.less";
@import "../assets/styles/animations.less";



.terminal_info_container {
  z-index: 3;
  position: absolute;
  width: 801px;
  height: 100%;
  right: 0;
  top: 72px;
  background-color: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.header_container {
  display: flex;
  height: 49px;
  align-items: center;
  justify-content: space-between;
  background: #d6e6ff;
  padding: 0px 25px;

  button {
    all: unset;
    cursor: pointer;
  }
}

.center_buttons_container {
  width: 243px;
  display: flex;
  justify-content: space-between;
  .selected_tab {
    button {
      cursor: pointer;
      border-bottom: 1px solid #4150b7;
      border-radius: 0px;
      transition: 0.7s;
    }
  }
  button {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 27px;
    height: 27px;
  }
  button:hover {
    background-color: #4151b746;
    border-radius: 50%;
    transition: 0.7s;
  }
}

.left_buttons_container {
  width: 90px;
  display: flex;
  justify-content: space-between;

  button {
    width: 27px;
    height: 27px;
    display: flex;
    border-radius: 50px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  button:hover {
    background-color: #4151b761;
    border-radius: 50px;
    transition: 0.3s;
  }

  button:active {
    outline-color: transparent;
    outline-style: solid;
    box-shadow: 0 0 0 6px #4150b7;
    background-color: none;
  }
}

.terminal_panel_tab {
  width: 100%;
}
</style>
